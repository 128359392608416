import { ChangeDetectorRef, Component, HostBinding, HostListener, Inject } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { Router } from '@angular/router';
import {
  AppInfoService,
  ApplicationUpdateService,
  AuthorityService,
  AuthService,
  AVAILBLE_MENU_ITEMS,
  CurrentPageService,
  MANAGE_CUSTOMER,
  MANAGE_EXTERNAL_USERS,
  MANAGE_USER_GROUPS,
  USE_ADMIN_PORTAL,
  VIEW_ADMIN,
  VIEW_AGGREGATED_POOL,
  VIEW_ANALOG_INPUT,
  VIEW_BATTERY,
  VIEW_BATTERY_MEASUREMENT_DATA,
  VIEW_BRP,
  VIEW_CONTROL,
  VIEW_CUSTOMER,
  VIEW_DEVICE,
  VIEW_GRID_POINT,
  VIEW_IMBALANCE_CORRECTION,
  VIEW_INTRA_DAY_DEAL_REPORTING,
  VIEW_INVOICE_RESULTS,
  VIEW_MEASUREMENT_COMPANY,
  VIEW_MEASUREMENT_DATA,
  VIEW_MEASUREMENT_UPLOAD_DATA,
  VIEW_MESSAGES,
  VIEW_OPERATION,
  VIEW_OPERATION_NOTIFICATION,
  VIEW_POOL,
  VIEW_POOL_AVAILABILITY,
  VIEW_INCIDENT_RESERVE_INVOICE_DATA,
  VIEW_RECIPIENT,
  VIEW_SERVICE_AGREEMENT,
  VIEW_TSO_AGREEMENT,
  VIEW_UNAVAILABILITY,
  MANAGE_ADMIN_ANNOUNCEMENTS
} from 'flex-app-shared';

import { get, has } from 'lodash-es';
import { BehaviorSubject, Observable } from 'rxjs';
import { registerGlobalAppInsights } from '../../../flex-app-shared/src/lib/app-insights/register-global-app-insights';
import { environment } from '../environments/environment';

const desktopBreakpoint = 768; // px

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Flex application - Powerhouse';
  loggedIn$: Observable<boolean>;

  showContents = false;
  error = null;
  mobileSidenavOpen = false;

  @HostBinding('class.environment-dev')
  isDev: boolean;

  @HostBinding('class.environment-feature')
  isFeature: boolean;

  @HostBinding('class.environment-acc')
  isAcc: boolean;

  @HostBinding('class.environment-prod')
  isProd: boolean;

  menuItems = [];

  requiredAuthorities: { [key: string]: string[] } = {
    '/customer-stepper': [MANAGE_CUSTOMER],
    '/customers': [VIEW_CUSTOMER],
    '/dashboard': [VIEW_CUSTOMER, VIEW_GRID_POINT, VIEW_DEVICE, VIEW_CONTROL, VIEW_RECIPIENT, VIEW_UNAVAILABILITY, VIEW_SERVICE_AGREEMENT],
    '/grid-points': [VIEW_GRID_POINT],
    '/devices': [VIEW_DEVICE],
    '/controls': [VIEW_CONTROL],
    '/service-agreements': [VIEW_SERVICE_AGREEMENT],
    '/unavailabilities': [VIEW_UNAVAILABILITY],
    '/measurement-companies': [VIEW_MEASUREMENT_COMPANY],
    '/measurement-data-download': [VIEW_MEASUREMENT_DATA],
    '/measurement-data-upload': [VIEW_MEASUREMENT_UPLOAD_DATA],
    '/balance-responsible-parties': [VIEW_BRP],
    '/tso-agreements': [VIEW_TSO_AGREEMENT],
    '/tso-auctions': [VIEW_ADMIN],
    '/pools': [VIEW_POOL],
    '/operations': [VIEW_OPERATION],
    '/messages': [VIEW_MESSAGES],
    '/recipients': [VIEW_RECIPIENT],
    '/users': [MANAGE_USER_GROUPS, MANAGE_EXTERNAL_USERS],
    '/admin': [VIEW_ADMIN, MANAGE_ADMIN_ANNOUNCEMENTS],
    '/incident-reserve-notice': [VIEW_OPERATION_NOTIFICATION],
    '/r3-invoice-data': [VIEW_INCIDENT_RESERVE_INVOICE_DATA],
    '/incident-reserve-invoice-results': [VIEW_INCIDENT_RESERVE_INVOICE_DATA],
    '/aggregated-pool': [VIEW_AGGREGATED_POOL],
    '/pool-availability': [VIEW_POOL_AVAILABILITY],
    '/batteries': [VIEW_BATTERY],
    '/battery-measurement-data': [VIEW_BATTERY_MEASUREMENT_DATA],
    '/analog-inputs': [VIEW_ANALOG_INPUT],
    '/intraday/imbalance-correction': [VIEW_IMBALANCE_CORRECTION],
    '/intraday/invoice-results': [VIEW_INVOICE_RESULTS],
    '/intraday/deal-reporting': [VIEW_INTRA_DAY_DEAL_REPORTING],
    '/group-authorities': [VIEW_ADMIN]
  };
  viewWidth: number;

  constructor(
    public matIconRegistry: MatIconRegistry,
    public router: Router,
    public authorityService: AuthorityService,
    public authService: AuthService,
    public cdr: ChangeDetectorRef,
    @Inject(AVAILBLE_MENU_ITEMS) public availableMenuItems: string[],
    public currentPageService: CurrentPageService,
    appInfoService: AppInfoService,
    applicationUpdateService: ApplicationUpdateService
  ) {
    if (environment.enableAppInsights) {
      registerGlobalAppInsights('flex-app-admin', this.router);
    }

    matIconRegistry.setDefaultFontSetClass('ic-inni');
    this.loggedIn$ = new BehaviorSubject(true);

    if (!environment.requireAuthentication) {
      this.showContents = true;
    }
    this.onResize();

    this.authorityService.authorities$.subscribe((authorities) => {
      if (!authorities) {
        this.error = 'unavailable';
      } else if ((authorities.length === 0 || !authorities.includes(USE_ADMIN_PORTAL)) && environment.requireAuthentication) {
        this.error = 'unauthorized';
      } else if (this.error && !this.showContents) {
        // We came from an error state (while the application was still uninitialized), so we shouldn't just show the application.
        // Instead we should check for updates, and then refresh
        applicationUpdateService.updateOrRefreshAfterReconnect();
      } else {
        this.error = null;
        this.showContents = true;
      }

      if (environment.requireAuthentication && authorities) {
        this.menuItems = this.availableMenuItems.filter(
          (menuItem) =>
            !has(this.requiredAuthorities, menuItem) ||
            get(this.requiredAuthorities, menuItem).some((requiredAuthority) => authorities.includes(requiredAuthority))
        );
      }
      this.cdr.detectChanges();
    });

    if (!environment.requireAuthentication) {
      this.menuItems = this.availableMenuItems;
    }

    appInfoService.getAppInfo().subscribe((result) => {
      switch (result.env) {
        case 'dev':
          this.isDev = true;
          break;
        case 'feature':
          this.isFeature = true;
          break;
        case 'acc':
          this.isAcc = true;
          break;
        case 'prod':
          this.isProd = true;
          break;
      }
    });
  }

  get desktopSidenavOpen(): boolean {
    return this.viewWidth > desktopBreakpoint;
  }

  get showHamburger(): boolean {
    return !this.desktopSidenavOpen;
  }

  logout(): void {
    this.authService.logout();
  }

  changePassword(): void {
    this.authService.changePasswordFlow();
  }

  toggleMobileSidenav(): void {
    this.mobileSidenavOpen = !this.mobileSidenavOpen;
  }

  @HostListener('window:resize')
  onResize(): void {
    this.viewWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  }
}
