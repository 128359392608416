import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  AppInfo,
  AppInfoService,
  AuthorityService,
  Capacity,
  CustomValidators,
  FnErrorMatcher,
  MixinBase,
  OnDestroyMixin,
  VIEW_ADMIN
} from 'flex-app-shared';
import moment from 'moment';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  ApplicationSetting,
  ApplicationSettingsService,
  isCapacityApplicationSetting,
  isVolumeApplicationSetting
} from '../shared/application-settings/application-settings.service';
import { MailService } from '../shared/mail/mail.service';
import { EmailTemplate, TemplateConfiguration, TemplateService } from '../shared/template/template.service';
import { TennetIsAliveService } from '../shared/tennet-is-alive/tennet-is-alive.service';
import { EditApplicationSettingComponent } from './edit-application-setting/edit-application-setting.component';
import { EditTemplateOptionComponent, EditTemplateOptionComponentData } from './edit-template-option/edit-template-option.component';
import { Announcement, AnnouncementService } from '../shared/announcement/announcement.service';
import { MotdDialogComponent } from '../../../../flex-app-shared/src/lib/material/motd/motd-dialog/motd-dialog.component';
import { AnnouncementDialogComponent } from './announcement-dialog/announcement-dialog.component';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent extends OnDestroyMixin(MixinBase) implements OnInit {
  hasViewAdmin$ = this.authorityService.hasAuthorities(VIEW_ADMIN);
  isIrAliveResult: any;
  isAncillaryServicesAliveResult: any;
  isMmcHubAliveResult: any;
  auctionResults: any;
  requestStatusesResult: any;

  pingMandrillResult: any;
  availableEmailTemplateOptions: ReadonlyArray<EmailTemplate>;
  applicationSettings: ReadonlyArray<ApplicationSetting>;
  mandrillEmailTemplatesDataSource = new MatTableDataSource();
  applicationSettingsDataSource = new MatTableDataSource<ApplicationSetting>();
  displayedMandrillTemplateOptionsColumns = ['identifier', 'templateName', 'subject', 'publishedAt', 'actions'];
  displayedApplicationSettingsColumns = ['name', 'value', 'actions'];
  appInfo$: Observable<AppInfo>;
  announcement: Announcement;
  showAnnouncementErrors = false;
  announcementForm = this.builder.group({
    enabled: false,
    title: ['', [Validators.required, Validators.maxLength(255)]],
    body: ['', [Validators.required]],
    validFromDate: [moment(), [Validators.required]],
    validFromTime: [this.getInitialValidFromTime(), [Validators.required, CustomValidators.timeValidator()]]
  });
  announcementErrorStateMatcher = new FnErrorMatcher(() => this.showAnnouncementErrors);
  @ViewChild(MatSort, { static: false }) private readonly sort: MatSort;

  constructor(
    private tennetIsAliveService: TennetIsAliveService,
    private mailService: MailService,
    private templateService: TemplateService,
    private applicationSettingsService: ApplicationSettingsService,
    private appInfoService: AppInfoService,
    private announcementService: AnnouncementService,
    private matDialog: MatDialog,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private builder: UntypedFormBuilder,
    private authorityService: AuthorityService
  ) {
    super();
  }

  isIncidentReserveAlive(): void {
    this.tennetIsAliveService.isIncidentReserveAlive().subscribe((result) => {
      this.isIrAliveResult = result;
    });
  }

  isAncillaryServicesAlive(): void {
    this.tennetIsAliveService.isAncillaryServicesAlive().subscribe((result) => {
      this.isAncillaryServicesAliveResult = result;
    });
  }

  isMmcHubAlive(): void {
    this.tennetIsAliveService.isMmcHubAlive().subscribe((result) => {
      this.isMmcHubAliveResult = result;
    });
  }

  getAuctionResults(): void {
    this.tennetIsAliveService.getAuctionResults().subscribe((result) => {
      this.auctionResults = result;
    });
  }

  sendStatusRequest(): void {
    this.tennetIsAliveService.requestStatuses().subscribe((result) => {
      this.requestStatusesResult = result;
    });
  }

  pingMandrill(): void {
    this.mailService.ping().subscribe((result) => {
      this.pingMandrillResult = result;
    });
  }

  ngOnInit(): void {
    this.hasViewAdmin$.subscribe((hasAdmin) => {
      if (hasAdmin) {
        this.initMandrillForm();
        this.initApplicationSettings();
      }
    });

    this.appInfo$ = this.appInfoService.getAppInfo();
    this.initAnnouncement();
  }

  getAvailableTemplate(templateOption: TemplateConfiguration): EmailTemplate {
    return (
      this.availableEmailTemplateOptions && this.availableEmailTemplateOptions.find((option) => option.name === templateOption.templateName)
    );
  }

  editTemplateOption(templateOption: TemplateConfiguration): void {
    const data: EditTemplateOptionComponentData = {
      templateConfiguration: templateOption
    };
    const dialogRef = this.matDialog.open(EditTemplateOptionComponent, {
      data,
      width: '70vw'
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.templateService.updateTemplateConfiguration(result).subscribe(() => {
          // SAVED
          this.snackBar.open('Updated template configuration', 'ok', {
            duration: 3000
          });
          this.initMandrillForm();
        });
      }
    });
  }

  editApplicationSetting(setting: ApplicationSetting): void {
    const dialogRef = this.matDialog.open(EditApplicationSettingComponent, {
      data: setting,
      minWidth: '400px'
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.applicationSettingsService.save(result).subscribe(() => {
          // SAVED
          this.snackBar.open('Updated application setting', 'ok', {
            duration: 3000
          });
          this.initApplicationSettings();
        });
      }
    });
  }

  displayValue(setting: ApplicationSetting): string {
    if (isCapacityApplicationSetting(setting)) return `${Capacity.value(setting.value)} ${setting.value.unit}`;
    if (isVolumeApplicationSetting(setting)) return `${setting.value.value} ${setting.value.unit}`;

    return setting.value.toString();
  }

  initAnnouncement(): void {
    this.announcementService.get().subscribe((announcement) => {
      this.announcement = announcement;

      if (!announcement) {
        return;
      }

      this.announcementForm.patchValue({
        ...announcement,
        validFromDate: announcement.validFrom,
        validFromTime: moment(announcement.validFrom).format('HH:mm')
      });
    });
  }

  previewAnnouncement(): void {
    this.showAnnouncementErrors = true;
    if (this.announcementForm.invalid) {
      return;
    }
    this.matDialog.open(MotdDialogComponent, { data: this.announcementForm.value, width: '500px' });
  }

  saveAnnouncement(): void {
    this.showAnnouncementErrors = true;
    if (this.announcementForm.invalid) {
      return;
    }
    const value = this.announcementForm.value;
    const validFrom = moment(value.validFromDate);
    const validFromTime = moment(value.validFromTime, 'HH:mm');
    validFrom.hour(validFromTime.hour());
    validFrom.minute(validFromTime.minute()).startOf('minute');

    const announcement: Announcement = {
      enabled: value.enabled,
      title: value.title,
      body: value.body,
      validFrom: validFrom.toISOString()
    };

    this.saveAndShowDialogWhenValidFromIsWithin5Minutes(validFrom, announcement);
  }

  private initMandrillForm(): void {
    this.templateService.getAvailableMailTemplates().subscribe((availableTemplates) => {
      this.availableEmailTemplateOptions = availableTemplates;
      setTimeout(() => (this.mandrillEmailTemplatesDataSource.sort = this.sort));
    });

    this.templateService.getAllTemplateConfigurations().subscribe((templateConfigurations) => {
      this.mandrillEmailTemplatesDataSource.data = templateConfigurations.filter(
        (templateConfiguration) => templateConfiguration.type === 'EMAIL'
      );
    });
  }

  private initApplicationSettings(): void {
    this.applicationSettingsService.getAll().subscribe((settings) => {
      this.applicationSettings = settings;
      this.applicationSettingsDataSource.data = [...settings];
    });
  }

  private getInitialValidFromTime(): string {
    return moment().add(5, 'minute').startOf('minute').format('HH:mm');
  }

  private saveAndShowDialogWhenValidFromIsWithin5Minutes(validFrom: moment.Moment, announcement: Announcement): void {
    if (moment().add(5, 'minutes').isAfter(validFrom) && announcement.enabled) {
      const dialogRef = this.matDialog.open(AnnouncementDialogComponent, {
        data: announcement
      });
      dialogRef
        .afterClosed()
        .pipe(filter((a) => !!a))
        .subscribe(() => {
          this.doAnnouncementSave(announcement);
        });
    } else {
      this.doAnnouncementSave(announcement);
    }
  }

  private doAnnouncementSave(announcement: Announcement): void {
    this.announcementService.save(announcement).subscribe(() => {
      if (announcement.enabled) {
        this.snackBar.open('Announcement successfully saved', null, { duration: 5000 });
      } else {
        this.snackBar.open('Announcement saved, but disabled!', null, { duration: 8000 });
      }
    });
  }
}
